import { bookNames, chapterCounts } from "../../bibleMeta";
// import "./BibleBox.css";
import MessageOutlinedIcon from "@material-ui/icons/MessageOutlined";
import NoteAddOutlinedIcon from "@material-ui/icons/NoteAddOutlined";
import { addHighlight, deleteHighlight } from "../../services/highlightService";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import CollectionsBookmarkIcon from "@mui/icons-material/CollectionsBookmark";
import { useEffect,useContext } from "react";
import ChapterTitle from "./chapterTitle";
import { LST_SLCTD_VERSE_LENGTH } from '../../utils/bibleconstants';
import { useMediaQuery } from "@material-ui/core";
import projector from "../../assets/images/projector.png";
import SwipeLeftIcon from "@mui/icons-material/SwipeLeft";
import SwipeRightIcon from "@mui/icons-material/SwipeRight";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import BooksComponent from "../Books/BooksComponent";
import  Shimmer  from "../VersesShimmerUi/VersesShimmerUi";
import {
Stack,
 } from "@mui/material";
import {
  FileCopyOutlined,
} from "@material-ui/icons";
import copy from "copy-to-clipboard";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { EffectCreative, Navigation } from "swiper/modules";
import "swiper/css/effect-creative";
import "swiper/css/navigation";
import toast, { Toaster } from "react-hot-toast";
import { useState } from "react";
import { BookContext } from "../../context/BibleProvider";
export default function BibleBox({
  user,
  userIsLoading,
  title,
  verseCount,
  globalVersions,
  bibleData,
  chapterNotes,
  setNoteVerseNo,
  chapterHighlights,
  setChapterHighlights,
  changeChapter,
  changeBook,
  changeVerse,
  handleToggle,
  errorMessage,
  lastSelectedVerses,
  setLastSelectedVerses,
  setVerseViewOpen,
  projectSlctVerse,
  setProjectSlctVerse,
  setBibleBoxProjector,
  bibleBoxProjector,
  
  lastSlctedVersesInLocalStorage,
}) {
  const {
    bookNo,
    oldBookNo,
    chapterNo,
    oldChapterNo,
    verseNo,
    chapterLoading,
    setChapterLoading,
  
  } = useContext(BookContext);
  const delay = ms => new Promise(resolve => setTimeout(resolve, ms));
  const [headers, setHeaders] = useState([]);
  const renderHeaders = async () => {
    await delay(400); 
 const headersContent = globalVersions.map((value, index) => (
      <h2 key={Number(index) + 1}>
        {bookNames[value]
          ? bookNames[value][Number(oldBookNo - 1)]
          : bookNames["english_nkjv"][Number(oldBookNo - 1)]}
        &nbsp;
        {oldChapterNo}
      </h2>
    ));

    setHeaders(headersContent);
  };
  useEffect(() => {
    renderHeaders();
  }, [oldBookNo,oldChapterNo]);

const [openBibleFullScreen, setOpenBibleFullScreen] = useState(false);
  const ifLargerDevice = useMediaQuery("(min-width: 1280px)");
  const ifMobile = useMediaQuery("(max-width: 580px)");
  const defaultFontSize = 18;
  const updatedVersions = localStorage.getItem("updatedVersions")
    ? localStorage.getItem("updatedVersions").split(",")
    : [];

  const openVerseView = index => {
    setVerseViewOpen(true);
    setBibleBoxProjector(true);
    setProjectSlctVerse(index);
  };

  const renderVerseVersions = verse => {
    const verseVersions = [];
    globalVersions.forEach(single => {
      let verseStyle = {};
      if (chapterHighlights[verse]) {
        verseStyle = {
          backgroundColor: chapterHighlights[verse],
        };
      }
      if (bibleData[single]) {
        const fontsize =
          ifMobile && updatedVersions.length > 1
            ? defaultFontSize
            : Number(localStorage.getItem("fontSize")) || 15;
        verseVersions.push(
          <div key={single} className={"column version version-" + single}>
            <span className="verse-number">{verse}.</span>
            <span
              className={`verse-text`}
              style={{ ...verseStyle, fontSize: `${fontsize}px` }}
            >
              {bibleData[single][verse]}
            </span>
          </div>
        );
      }
    });

    return verseVersions;
  };

  const handleAddNoteClick = verse => {
    setNoteVerseNo(verse);
    handleToggle("noteAddBox");
  };

  const handleViewNoteClick = verse => {
    setNoteVerseNo(verse);
    handleToggle("notes");
  };

  const handleHighlightClick = (verse, color) => {
    addHighlight(oldBookNo, oldChapterNo, verse, "english_nkjv", color).then(
      response => {
        const newHighlights = { ...chapterHighlights };
        newHighlights[verse] = color;
        setChapterHighlights(newHighlights);
      }
    );
  };

  const handleHighlightRemoveClick = verse => {
    deleteHighlight(oldBookNo, oldChapterNo, verse).then(() => {
      let newHighlights = {};
      for (const [key, value] of Object.entries(chapterHighlights)) {
        if (String(key) !== String(verse)) {
          newHighlights[key] = value;
        } else {
        }
      }
      setChapterHighlights(newHighlights);
    });
  };

  const verseCopy = verse => {
    let copied = false;
    globalVersions.forEach(single => {
      if (bibleData[single]) {
        const book = bookNames[single][bookNo - 1];
        const verseCopied = `${book} ${chapterNo}:${verse}. ${bibleData[single][verse]}`;
        copy(verseCopied);
        copied = true;
      }
    });
    if (copied) {
      toast.success("Copied to Clipboard", {
        position: "top-center",
        className: "toast-middle",
      });
    }
  };

  const addVerseToLastReadVerse = verse => {
    const existingLastSlctedVerses = [...lastSelectedVerses];
    let exist = false;
    for (let i = 0; i < existingLastSlctedVerses.length; i++) {
      if (
        existingLastSlctedVerses[i].book === bookNo &&
        existingLastSlctedVerses[i].chapter === chapterNo &&
        existingLastSlctedVerses[i].verse === verse
      ) {
        exist = true;
        break;
      }
    }
    if (exist) {
      toast.error("Already Selected", {
        position: "top-center",
        className: "toast-middle",
      });
    }
    if (!exist) {
      if (existingLastSlctedVerses.length >= LST_SLCTD_VERSE_LENGTH) {
        existingLastSlctedVerses.shift();
      }

      existingLastSlctedVerses.push({
        book: bookNo,
        chapter: chapterNo,
        verse: verse,
      });
      toast.success("Added to Selected Verses", {
        position: "top-center",
        className: "toast-middle",
      });
    }
    setLastSelectedVerses(existingLastSlctedVerses);
    localStorage.setItem(
      "lastSelectedVerses",
      JSON.stringify(existingLastSlctedVerses)
    );
  };

  const renderVerseOptions = verse => {
    return (
      <div className="verse-options">
        <div className="primary-options">
          {chapterNotes[verse] && chapterNotes[verse].length !== 0 && (
            <>
              <button
                className="note-button"
                onClick={() => {
                  handleViewNoteClick(verse);
                }}
                title="View Note"
              >
                <MessageOutlinedIcon />
              </button>
            </>
          )}
        </div>
        <div className="secondary-options">
          <div className="highlight-buttons">
            <button
              style={{
                height: ifMobile ? "17px" : "20px",
                width: ifMobile ? "17px" : "20px",
              }}
              onClick={() => {
                handleHighlightClick(verse, "#D5F4CB");
              }}
              className="highlight-green-button"
            ></button>
            <button
              style={{
                height: ifMobile ? "17px" : "20px",
                width: ifMobile ? "17px" : "20px",
              }}
              onClick={() => {
                handleHighlightClick(verse, "#D2E8FF");
              }}
              className="highlight-blue-button"
            ></button>
            <button
              style={{
                height: ifMobile ? "17px" : "20px",
                width: ifMobile ? "17px" : "20px",
              }}
              onClick={() => {
                handleHighlightClick(verse, "#F8D1DC");
              }}
              className="highlight-rose-button"
            ></button>
            <button
              style={{
                height: ifMobile ? "17px" : "20px",
                width: ifMobile ? "17px" : "20px",
              }}
              onClick={() => {
                handleHighlightClick(verse, "#FFEEC9");
              }}
              className="highlight-gold-button"
            ></button>
          </div>
          <div className="other-options">
            <button
              onClick={() => {
                handleHighlightRemoveClick(verse);
              }}
              className="highlight-none-button"
            
            >
              <NotInterestedIcon
                style={{ fontSize: ifMobile ? "17px" : "20px" }}
              />
            </button>
            <button
              onClick={() => {
                handleAddNoteClick(verse);
              }}
              title="Add Note"
              className="addbutton"
              style={{ fontSize: ifMobile ? "17px" : "20px" }}
            >
              <NoteAddOutlinedIcon />
            </button>
          </div>
          <div className="other-options">
            <button
              onClick={() => {
                verseCopy(verse);
              }}
              title="Copy Verse"
            >
              <FileCopyOutlined
                style={{ fontSize: ifMobile ? "17px" : "20px" }}
              />
            </button>
          </div>
          <div className="other-options">
            <button
              onClick={() => {
                addVerseToLastReadVerse(verse);
              }}
              title={
                lastSelectedVerses.some(
                  item =>
                    item.book === bookNo &&
                    item.chapter === chapterNo &&
                    item.verse === verse
                )
                  ? "Already Selected"
                  : "Add to Selected Verses"
              }
            >
              <CollectionsBookmarkIcon
                style={{ fontSize: ifMobile ? "17px" : "20px" }}
              />
            </button>
          </div>
          {!openBibleFullScreen && (
            <div className="other-options">
              <button
                onClick={() => {
                  openVerseView(verse);
                }}
                title="Show on Projector"
              >
                <img
                  src={projector}
                  alt="projector"
                  className="projectorIcon"
                />
              </button>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderVerses = () => {
    const verses = [];
    
    for (let i = 1; i <= verseCount; i++) {
      verses.push(
        <div className="bible-verse" key={i} id={"bible-verse-" + i}>
          <div className="versions">{renderVerseVersions(i)}</div>
          {user && renderVerseOptions(i)}
        </div>
      );
    }
    return verses;
  };

  useEffect(() => {
    const bibleElement = document.querySelector(".bible-box .box-content");
    bibleElement.addEventListener("mouseup", event => {
      const searchInputElement = document.querySelector(
        ".search-box .search-term-input"
      );
      if (!bibleElement || !searchInputElement) {
        return;
      }
      let selection = "";
      if (window.getSelection) {
        selection = window.getSelection();
      } else if (document.selection) {
        selection = document.selection.createRange();
      }
      searchInputElement.value = selection;
    });
  }, []);

  const handleSlideChange = async swipe => {
    const { activeIndex, slides } = swipe;
    const names = bookNames[globalVersions[0]] ?? bookNames["english_nkjv"];
    if (swipe.swipeDirection === "next") {
      if (activeIndex === slides.length - 1) {
        // If last chapter move to 1st chapter.
        if (chapterCounts[Number(oldBookNo) - 1] < Number(chapterNo) + 1) {
          if (names.length < Number(oldBookNo) + 1) {
            await changeBook(1);
            await changeChapter(1);
            await changeVerse(1,"chapterSwitch");
          } else {
            await changeBook(Number(bookNo) + 1);
            await changeChapter(1);
            await changeVerse(1,"chapterSwitch");
          }
        } else {
         
          await changeBook(Number(oldBookNo));
          await changeChapter(Number(chapterNo) + 1);
          await changeVerse(1,"chapterSwitch");
        }
      }
    }

    if (swipe.swipeDirection === "prev") {
      if (activeIndex === 0) {
        // Add a check to prevent setting chapterNo below 1
        if (Number(chapterNo) > 1) {
          await changeBook(Number(oldBookNo));
          await changeChapter(Number(chapterNo) - 1);
          await changeVerse(1,"chapterPrevious");
        }
      }
    }
  };

const delayChaptersUpdate = ms =>
     new Promise(resolve => {
       const timeoutId = setTimeout(() => {
         resolve();
         clearTimeout(timeoutId);
       }, ms);
     });
   const handleNextChapter = async () => {
    setChapterLoading(true)
    const names = bookNames[globalVersions[0]] ?? bookNames["english_nkjv"];
    const swiperContainer = document.querySelector(".swiper-slide-active");
    if (swiperContainer) {
      swiperContainer.style.display = "none"; 
      await delayChaptersUpdate(100); 
      if (chapterCounts[Number(oldBookNo) - 1] < Number(chapterNo) + 1) {
        if (names.length < Number(oldBookNo) + 1) {
          await changeBook(1);
          await changeChapter(1);
          await changeVerse(1, "chapterSwitch");
        } else {
          await changeBook(Number(bookNo) + 1);
          await changeChapter(1);
          await changeVerse(1, "chapterSwitch");
        }
      } else {
        await changeBook(Number(oldBookNo));
        await changeChapter(Number(chapterNo) + 1);
        await changeVerse(1, "chapterSwitch");
      }
      swiperContainer.style.display = "block"; 
      const firstVerse = swiperContainer.querySelector("#bible-verse-1");
      if (firstVerse) {
        firstVerse.style.display = "block";
        firstVerse.scrollIntoView({
          behavior: "instant",
          block: "start",
        })
           setChapterLoading(false);
      }
    }
  };

  const handlePrevChapter = async () => {
     setChapterLoading(true);
    const swiperContainer = document.querySelector(".swiper-slide-active");
    if (swiperContainer) {
      swiperContainer.style.display = "none"
      await delayChaptersUpdate(100); 
      if (Number(chapterNo) > 1) {
        await changeBook(Number(oldBookNo));
        await changeChapter(Number(chapterNo) - 1);
        await changeVerse(1, "chapterPrevious");
      }
      swiperContainer.style.display = "block"; 
      const firstVerse = swiperContainer.querySelector("#bible-verse-1");
      if (firstVerse) {
        firstVerse.style.display = "block";
        firstVerse.scrollIntoView({
          behavior: "instant",
          block: "start",
        });
         setChapterLoading(false);
      }
    }
  };
 useEffect(() => {
  const onFullScreenChange = () => {
  const isFullScreen =
        !!document.fullscreenElement || 
        !!document.webkitFullscreenElement || 
        !!document.mozFullScreenElement || 
      !!document.msFullscreenElement;
    setOpenBibleFullScreen(isFullScreen);
    };
    document.addEventListener("fullscreenchange", onFullScreenChange); 
    document.addEventListener("webkitfullscreenchange", onFullScreenChange); 
    document.addEventListener("mozfullscreenchange", onFullScreenChange); 
    document.addEventListener("MSFullscreenChange", onFullScreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", onFullScreenChange);
      document.removeEventListener(
        "webkitfullscreenchange",
        onFullScreenChange
      );
      document.removeEventListener("mozfullscreenchange", onFullScreenChange);
      document.removeEventListener("MSFullscreenChange", onFullScreenChange);
    };
  }, []);

  const [safariBrowser, setSafariBrowser] = useState(false);
  //this function open's fullscreen bible
  const openFullScreenBibleFn = () => {
    const fullScreenElement = document.querySelector(".fullscreenbible");
    if (!fullScreenElement) {
      console.log("fullScreenElement not found");
      return;
    }
    const isSafari =
      /^((?!chrome|android).)*safari/i.test(navigator.userAgent) &&
      !navigator.userAgent.includes("Chrome");
    try {
      if (isSafari) {
        setSafariBrowser(true);
        if (fullScreenElement.webkitRequestFullscreen) {
          fullScreenElement.webkitRequestFullscreen();
           setOpenBibleFullScreen(true);
        }
        else {
           console.warn("Fullscreen not supported on Safari");
        }
      }
      else {
        if (fullScreenElement.requestFullscreen) {
          fullScreenElement.requestFullscreen();
          setOpenBibleFullScreen(true);
        }
        else if (fullScreenElement.mozRequestFullScreen) {
          fullScreenElement.mozRequestFullScreen();
          setOpenBibleFullScreen(true);
        }
        else if (fullScreenElement.msRequestFullscreen) {
          fullScreenElement.msRequestFullscreen();
          setOpenBibleFullScreen(true);
        }
        else {
            console.warn("Fullscreen API not supported on this browser");
        }
    }
    }
    catch (error) {
       console.error("Error while trying to open fullscreen:", error);
    }


  };

  //this function closes's fullscreen bible
  const closeFullScreenBible = () => {
    setOpenBibleFullScreen(false);
    try {
      if (document.fullscreenElement || document.webkitFullscreenElement) {
        document
          .exitFullscreen?.()
          .catch(error => console.warn("Error exiting fullscreen:", error));
        document.webkitExitFullscreen?.();
        document.msExitFullscreen?.();
        document.mozCancelFullScreen?.();
      }
    } catch (error) {
      console.error("Error while handling fullscreen exit:", error);
    }
  };

  return (
    <div
      className="box bible-box fullscreenbible"
      id={safariBrowser && ifMobile ? "fullScreenBibleContent" : ""}
    >
      {/* <div id="loading-indicator" style={{display: "none"}}>
        Loading...
      </div> */}
      <Toaster />

      <Stack direction="row" alignItems="center">
        <div
          className="box-header"
          style={{ width: "100%", position: "relative", userSelect: "none" }}
        >
          {openBibleFullScreen && (
            <div className="books-popup">
              <BooksComponent
                bookNo={bookNo}
                changeBook={changeBook}
                chapterNo={chapterNo}
                changeChapter={changeChapter}
                changeVerse={changeVerse}
                verseNo={verseNo}
                verseCount={verseCount}
                globalVersions={globalVersions}
                lastSelectedVerses={lastSelectedVerses}
                setLastSelectedVerses={setLastSelectedVerses}
                lastSlctedVersesInLocalStorage={lastSlctedVersesInLocalStorage}
              />
            </div>
          )}

          {headers}
          <div
            title={openBibleFullScreen ? "Close FullScreen" : "Full Screen"}
            style={{
              cursor: "pointer",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {openBibleFullScreen ? (
              <CloseFullscreenIcon
                onClick={closeFullScreenBible}
                className="biblefullscreen"
              />
            ) : (
              <OpenInFullIcon
                onClick={openFullScreenBibleFn}
                className="biblefullscreen"
              />
            )}
          </div>
        </div>
      </Stack>

      <ChapterTitle book={bookNo} chapter={chapterNo} vers={globalVersions} />
      <div className="swiper">
        <div
          className={
            openBibleFullScreen ? "box-content fullcontent" : "box-content"
          }
        >
          {chapterLoading ? (
            <Shimmer />
          ) : (
            <Swiper
              // grabCursor={true}
              modules={[EffectCreative, Navigation]}
              className="mySwiper"
              slidesPerView={1}
              onSlideChange={handleSlideChange}
              // style={{ height: "100%" }}
              loop={true}
              effect="creative"
              creativeEffect={{
                prev: {
                  shadow: true,
                  translate: [0, 0, -200],
                  rotate: [0, 0, -10],
                },
                next: {
                  translate: ["100%", 0, 0],
                  rotate: [0, 0, 10],
                },
              }}
              touchStartPreventDefault={false}
              style={{
                transition: "transform 1s ease-in-out",
              }}
              navigation={{
                prevEl: ".custom-prev",
                nextEl: ".custom-next",
              }}
            >
              <SwiperSlide>
                {errorMessage ? (
                  <div className="error-message-container ">
                    <div className="error-message">
                      <div style={{ flex: 1 }}>
                        <strong>Error!</strong> {errorMessage}
                      </div>
                    </div>
                  </div>
                ) : verseCount > 0 ? (
                  renderVerses()
                ) : (
                  <Shimmer />
                )}
              </SwiperSlide>
              <SwiperSlide>
                {errorMessage ? (
                  <div className="error-message-container ">
                    <div className="error-message">
                      <div style={{ flex: 1 }}>
                        <strong>Error!</strong> {errorMessage}
                      </div>
                    </div>
                  </div>
                ) : verseCount > 0 ? (
                  renderVerses()
                ) : (
                  <Shimmer />
                )}
              </SwiperSlide>
            </Swiper>
          )}
        </div>
        {ifLargerDevice ? (
          <>
            <div
              className="swiper-button-prev"
              onClick={handlePrevChapter}
            ></div>
            <div
              className="swiper-button-next"
              onClick={handleNextChapter}
            ></div>
          </>
        ) : (
          <>
            <div className="swipeLeftChapter custom-prev">
              <SwipeLeftIcon
                onClick={handlePrevChapter}
                className=""
                style={{
                  fontSize: ifMobile ? "1.5rem" : "2.5rem",
                  color: "white",
                  opacity: "0.8",
                  transform: "rotate(-17deg)",
                }}
              />
            </div>
            <div className="swipeRightChapter custom-next">
              <SwipeRightIcon
                className=""
                onClick={handleNextChapter}
                style={{
                  fontSize: ifMobile ? "1.5rem" : "2.5rem",
                  color: "white",
                  opacity: "0.8",
                  transform: "rotate(17deg)",
                }}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
