import { useEffect, useState,useContext } from "react";
import { getCommentary } from "../../services/bibleService";
import { BookContext } from "../../context/BibleProvider";
export default function CommentaryBox({toggles, handleClose, mobileCommentry, setMobileCommentry, errorMessage }) {
  const { oldBookNo,
          oldChapterNo}=useContext(BookContext)
  const closeCommentaryBox = () => {
    handleClose('commentry');
    setMobileCommentry(false);
  };
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
   if (!errorMessage && !isLoading) {
     GetCommentary();
   }
  }, [oldBookNo, oldChapterNo]);

  const [commentary, setCommentary] = useState("");
  const GetCommentary = () => {
    getCommentary(oldBookNo, oldChapterNo)
      .then((res) => {
        setCommentary(res.data);
      })
      .catch((err) => { });
  };
   if (errorMessage || isLoading) return null;
  return (toggles.commentry ? (
    <div className={`box commentary-box aside ${mobileCommentry ? "" : 'mobile-hidden'}`}>
      <div className="box-header">
        <h2>Commentary</h2>
        <span className="close-buttonn" onClick={closeCommentaryBox}>
          &times;
        </span>
      </div>
      <div
        style={{ padding: "10px" }}
        dangerouslySetInnerHTML={{ __html: commentary }}
      />
    </div>
  ) : null);
}
