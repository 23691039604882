import { bookLanguage, bookNames, chapterCounts } from "../../bibleMeta";
import { useState, useRef, useEffect } from "react";
import { useMediaQuery } from "@material-ui/core";
import "./bookcomponent.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

export default function BooksComponent({
  bookNo,
  changeBook,
  chapterNo,
  changeChapter,
  verseNo,
  changeVerse,
  verseCount,
  globalVersions,
  lastSelectedVerses,
  lastSlctedVersesInLocalStorage,
  setLastSelectedVerses,
  
}) {
  const [activeTab, setActiveTab] = useState(0);
  const [renderBooks, setRenderBooks] = useState(false);
  const [renderChaptersTab, setRenderChaptersTab] = useState(false)
  const [isChapterSelected, setIsChapterSelected] = useState(() => {
    return JSON.parse(localStorage.getItem("isChapterSelected")) || false;
  });
  const [isVerseSelected, setIsVerseSelected] = useState(() => {
    return JSON.parse(localStorage.getItem("isVerseSelected")) || false;
  })
   
  const bookPaperRef = useRef(null);
  
  const ifMobile = useMediaQuery("(max-width:480px)");


  useEffect(() => {
    const handleOutsideClick = event => {
      if (
        bookPaperRef.current &&
        !bookPaperRef.current.contains(event.target) &&
        event.target.id !== "booksButton"
      ) {
        setRenderBooks(false);
        }
    };
     document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [renderBooks]);

  const handleBookDialogClose = () => {
    setRenderBooks(false);
  }
useEffect(() => {
 if (activeTab === 1 && !isChapterSelected && !isVerseSelected) {
  const timeOut = setTimeout(async () => {
   await changeChapter(1);
     await changeVerse(1);
     setActiveTab(0)
   
 }, 4000);

   return () => clearTimeout(timeOut) ;
 }

  if (activeTab === 2) {
   
    const timeOut = setTimeout(
      () => {
         handleBookDialogClose();
        setActiveTab(0);
      },
      isVerseSelected ? 0 : 6000
    );
    if (!isVerseSelected) {
      changeVerse(1);
    }
    return () => clearTimeout(timeOut);
  }
}, [activeTab,isChapterSelected, isVerseSelected]);

  const toggleBooks = event => {
    event.stopPropagation();
    setRenderBooks(prevBook => !prevBook);
    setActiveTab(0);
  };

  const renderOldTestamentBooks = () => {
    const names = bookNames[globalVersions[0]] ?? bookNames["english_nkjv"];
    const oldTestamentBooks = names
      .map((name, index) => {
        return (
          <div key={index}>
            <button
              className="bookButton"
              style={{
                maxWidth: ifMobile ? "100px" : "150px",
                overflowY: "auto",
              }}
              onClick={() => {
                handleBookChange(Number(index) + 1);
              }}
            >
              {name}
            </button>
          </div>
        );
      })
      .slice(0, 39);
    return oldTestamentBooks;
  };
  const renderNewTestamentBooks = () => {
    const names = bookNames[globalVersions[0]] ?? bookNames["english_nkjv"];
    const newTestamentBooks = names
      .map((name, index) => {
        return (
          <div key={index}>
            <button
              className="bookButton"
              style={{ maxWidth: "150px", overflowY: "auto" }}
              onClick={() => {
                handleBookChange(Number(index) + 1);
              }}
            >
              {name}
            </button>
          </div>
        );
      })
      .slice(39, 66);
    return newTestamentBooks;
  };
  const renderChapters = () => {
    const chapterOptions = [];
    for (let i = 1; i <= chapterCounts[Number(bookNo) - 1]; i++) {
      chapterOptions.push(
        <div key={Number(i)}>
          <button
            className="book-button"
            onClick={() => {
              handleChapterChange(Number(i));
            }}
          >
            {i}
          </button>
        </div>
      );
    }
    return chapterOptions;
  };
  const renderVerses = () => {
    const verseOptions = [];
    for (let i = 1; i <= verseCount; i++) {
      verseOptions.push(
        <div key={i}>
          <button
            className="book-button"
            onClick={() => {
              handleVerseChange(i);
            }}
          >
            {i}
          </button>
        </div>
      );
    }
    return verseOptions;
  };
  const handleBookChange = value => {
    changeBook(value);
     localStorage.setItem("isChapterSelected", false);
    setIsChapterSelected(false);
    localStorage.setItem("isVerseSelected", false);
     setIsVerseSelected(false);
     setActiveTab(1);
  };
  const handleChapterChange = value => {
    changeChapter(value);
     localStorage.setItem("isChapterSelected", true);
     setIsChapterSelected(true);
     localStorage.setItem("isVerseSelected", false);
     setIsVerseSelected(false);
      setActiveTab(2); 
  };
  const handleVerseChange = value => {
    changeVerse(value);
     localStorage.setItem("isVerseSelected", true);
     setIsVerseSelected(true);
    lastSlctedVersesInLocalStorage(
      localStorage.getItem("book_no") ?? 1,
      localStorage.getItem("chapter_no") ?? 1,
      value
    );
    if (lastSelectedVerses.length === 0) {
      let arr = [...lastSelectedVerses] || [];
      arr.push({
        book: localStorage.getItem("book_no") ?? 1,
        chapter: localStorage.getItem("chapter_no") ?? 1,
        verse: value,
      });
      setLastSelectedVerses(arr);
      localStorage.setItem("lastSelectedVerses", JSON.stringify(arr));
    }
    setRenderBooks(false);
  };

  const OTdesiredLanguage = () => {
    const OTestament = [];
    globalVersions.forEach((value, i) => {
      const languageObject = bookLanguage.find(item => item[value]);
      OTestament.push(
        i === 0 && (
          <p key={i} className="old_new">
            {languageObject ? languageObject[value].old : ""}
          </p>
        )
      );
    });
    return OTestament;
  };
  const NTdesiredLanguage = () => {
    const NTestament = [];
    globalVersions.forEach((value, i) => {
      const languageObject = bookLanguage.find(item => item[value]);
      NTestament.push(
        i === 0 && (
          <p key={i} className="old_new">
            {languageObject ? languageObject[value].new : ""}
          </p>
        )
      );
    });
    return NTestament;
  };
  const handleTabValueChange = (newValue) => {
    setActiveTab(newValue);
  };
  const openChpaterTab = (e) => {
    setRenderBooks(prevBook => !prevBook);
    setActiveTab(1);
  }

  return (
    <div className="background">
      <div style={{ display: "flex" }}>
        <div>
          <button
            id="booksButtonn"
            className="booksButton booksbutton"
            onClick={toggleBooks}
            style={{ userSelect: "none" }}
            onMouseEnter={e => {
              e.target.style.backgroundColor = "rgb(3,27,114)";
              e.target.style.borderRadius = "5px 0px 0px 5px";
            }}
            onMouseLeave={e => {
              e.target.style.backgroundColor = "#3a65ff";
              e.target.style.borderRadius = " 5px 0px 0px 5px";
            }}
          >
            Books
          </button>
        </div>
        <div>
          <button
            id="chaptBtnMobilee"
            onClick={e => openChpaterTab(e)}
            style={{
              userSelect: "none",
              marginLeft: "3px",
            }}
          >
            {activeTab === 1 || activeTab === 2 ? (
              <KeyboardArrowUpIcon
                style={{
                  width: "100%",
                  height: "100%",
                }}
                className="arrowupdown"
                onMouseEnter={e => {
                  e.target.style.backgroundColor = "rgb(3,27,114)";
                  e.target.style.borderRadius = "0px 5px 5px 0px";
                }}
                onMouseLeave={e => {
                  e.target.style.backgroundColor = "#3a65ff";
                  e.target.style.borderRadius = " 0px 5px 5px 0px";
                }}
              />
            ) : (
              <KeyboardArrowDownIcon
                className="arrowupdown"
                style={{
                  width: "100%",
                  height: "100%",
                }}
                onMouseEnter={e => {
                  e.target.style.backgroundColor = "rgb(3,27,114)";
                  e.target.style.borderRadius = "0px 5px 5px 0px";
                }}
                onMouseLeave={e => {
                  e.target.style.backgroundColor = "#3a65ff";
                  e.target.style.borderRadius = " 0px 5px 5px 0px";
                }}
              />
            )}
          </button>
        </div>
      </div>

      {renderBooks && (
        <div
          className="bookPaper renderBooks"
          style={{
            maxWidth: "750px",
            width: "740px",
            borderRadius: "5px",
            border: "none",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          }}
          ref={bookPaperRef}
        >
          <div className="tabButtons">
            <div
              className={activeTab === 0 ? "active-tab tabButton" : "tabButton"}
              onClick={() => setActiveTab(0)}
            >
              Book
            </div>
            <div
              className={
                activeTab === 1 ? "active-tab  tabButton" : " tabButton"
              }
              onClick={() => setActiveTab(1)}
            >
              Chapter
            </div>
            <div
              className={
                activeTab === 2 ? "active-tab  tabButton" : " tabButton"
              }
              onClick={() => setActiveTab(2)}
            >
              Verse
            </div>
          </div>

          {/* Tab Content */}
          {activeTab === 0 && (
            <div className="tabContent">
              <div className="notMobile ">
                {OTdesiredLanguage()}
                <div className="bookAlign" onClick={() => setActiveTab(1)}>
                  {renderOldTestamentBooks()}
                </div>
                {NTdesiredLanguage()}
                <div className="NTbookAlign" onClick={() => setActiveTab(1)}>
                  {renderNewTestamentBooks()}
                </div>
              </div>
              <div className="mobile">
                <div>
                  {OTdesiredLanguage()}
                  <div
                    onClick={() => setActiveTab(1)}
                    style={{ overflowY: "scroll" }}
                  >
                    {renderOldTestamentBooks()}
                  </div>
                </div>
                <div>
                  {NTdesiredLanguage()}
                  <div onClick={() => setActiveTab(1)}>
                    {renderNewTestamentBooks()}
                  </div>
                </div>
              </div>
            </div>
          )}
          {activeTab === 1 && (
            <div className="tabContent" onClick={() => setActiveTab(2)}>
              <span
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                  flexWrap: "wrap",
                }}
              >
                {renderChapters()}
              </span>
            </div>
          )}
          {activeTab === 2 && (
            <div className="tabContent" onClick={() => setRenderBooks(false)}>
              <span
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                  flexWrap: "wrap",
                }}
              >
                {renderVerses()}
              </span>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
