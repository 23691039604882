import BibleBox from "../../components/BibleBox/BibleBox";
import BiblePageOptions from "../../components/BiblePageOptions/BiblePageOptions";
import SearchBox from "../../components/SearchBox/SearchBox";
import CommentaryBox from "../../components/CommentaryBox/CommentaryBox";
import DictionaryBox from "../../components/DictionaryBox/DictionaryBox";
import TorreyBox from "../../components/TorreyBox/TorreyBox";
import NoteAddBox from "../../components/NoteAddBox/NoteAddBox";
import NoteEditBox from "../../components/NoteEditBox/NoteEditBox";
import NoteListBox from "../../components/NoteListBox/NoteListBox";
import VerseViewModal from "../../components/VerseViewModal/VerseViewModal";
import Article from "../../components/ArticleBox/Article";
import { useEffect, useState } from "react";

export default function BiblePage({
  user,
  userIsLoading,
 
  changeBook,
 
  changeChapter,
  changeBookAndChapter,
  
  changeVerse,
  verseCount,
  setVerseCount,
  globalVersions,
  setGlobalVersions,
  addVersion,
  removeVersion,
  changeVersions,
  primaryVersion,
  setPrimaryVersion,
  bibleData,
  chapterNotes,
  setChapterNotes,
  noteVerseNo,
  setNoteVerseNo,
  chapterHighlights,
  setChapterHighlights,
  setCurrentPage,
  
  lastSlctedVersesInLocalStorage,
  lastSelectedVerses,
  setLastSelectedVerses,
  errorMessage,
}) {
  const [verseViewOpen, setVerseViewOpen] = useState(false);
  const [currentEditNote, setCurrentEditNote] = useState();
 const [projectSlctVerse, setProjectSlctVerse] = useState(1);
 const [bibleBoxProjector, setBibleBoxProjector] = useState(true);
  useEffect(() => {
    setCurrentPage("bible");
  }, []);

  const initialToggles = {
    search: false,
    commentry: true,
    dictionary: false,
    notes: false,
    torrey: false,
    article: false,
    noteAddBox: false,
    noteEditBox: false,
  };
  const [toggles, setToggles] = useState(initialToggles);
  const [mobileCommentry, setMobileCommentry] = useState(false);
  const [mobileViewSearch, setMobileViewSearch] = useState(false);
 
  const handleToggle = key => {
    const updatedToggles = { [key]: true };
    setToggles(updatedToggles);
  };

  const handleClose = key => {
    const updatedToggles = { [key]: false };
    setToggles(updatedToggles);
  };

  return (
    <div className="bible-page page">
      <BiblePageOptions
   
        changeBook={changeBook}
 
        changeChapter={changeChapter}
      
        changeVerse={changeVerse}
        verseCount={verseCount}
        globalVersions={globalVersions}
        changeVersions={changeVersions}
        primaryVersion={primaryVersion}
        setPrimaryVersion={setPrimaryVersion}
        setVerseViewOpen={setVerseViewOpen}
   
        lastSlctedVersesInLocalStorage={lastSlctedVersesInLocalStorage}
        lastSelectedVerses={lastSelectedVerses}
        setLastSelectedVerses={setLastSelectedVerses}
        handleToggle={handleToggle}
        toggles={toggles}
        setMobileCommentry={setMobileCommentry}
        setMobileViewSearch={setMobileViewSearch}
      />
      <div className="page-contents">
        <BibleBox
          bibleBoxProjector={bibleBoxProjector}
          setBibleBoxProjector={setBibleBoxProjector}
          projectSlctVerse={projectSlctVerse}
          setProjectSlctVerse={setProjectSlctVerse}
          setVerseViewOpen={setVerseViewOpen}
          errorMessage={errorMessage}
          user={user}
          userIsLoading={userIsLoading}
          
          verseCount={verseCount}
          globalVersions={globalVersions}
          bibleData={bibleData}
          chapterNotes={chapterNotes}
          setNoteVerseNo={setNoteVerseNo}
          chapterHighlights={chapterHighlights}
          setChapterHighlights={setChapterHighlights}
          changeChapter={changeChapter}
          changeBook={changeBook}
          changeVerse={changeVerse}
          handleToggle={handleToggle}
          lastSelectedVerses={lastSelectedVerses}
          setLastSelectedVerses={setLastSelectedVerses}
          lastSlctedVersesInLocalStorage={lastSlctedVersesInLocalStorage}
        />
        <SearchBox
          changeBookAndChapter={changeBookAndChapter}
          changeVerse={changeVerse}
          lastSlctedVersesInLocalStorage={lastSlctedVersesInLocalStorage}
          toggles={toggles}
          handleClose={handleClose}
          mobileViewSearch={mobileViewSearch}
          setMobileViewSearch={setMobileViewSearch}
        />
        <CommentaryBox
          errorMessage={errorMessage}
         
          toggles={toggles}
          handleClose={handleClose}
          mobileCommentry={mobileCommentry}
          setMobileCommentry={setMobileCommentry}
        />
        <DictionaryBox toggles={toggles} handleClose={handleClose} />
        <TorreyBox toggles={toggles} handleClose={handleClose} />
        <Article toggles={toggles} handleClose={handleClose} />
        {/* <ExtrasBox /> */}
        <NoteListBox
          chapterNotes={chapterNotes}
          setChapterNotes={setChapterNotes}
          noteVerseNo={noteVerseNo}
          setNoteVerseNo={setNoteVerseNo}
          setCurrentEditNote={setCurrentEditNote}
          toggles={toggles}
          handleClose={handleClose}
          handleToggle={handleToggle}
        />
        <NoteAddBox
         
          noteVerseNo={noteVerseNo}
          chapterNotes={chapterNotes}
          setChapterNotes={setChapterNotes}
          toggles={toggles}
          handleToggle={handleToggle}
          handleClose={handleClose}
        />
        <NoteEditBox
          currentEditNote={currentEditNote}
          chapterNotes={chapterNotes}
          setChapterNotes={setChapterNotes}
          setNoteVerseNo={setNoteVerseNo}
          toggles={toggles}
          handleToggle={handleToggle}
          handleClose={handleClose}
        />
        {verseViewOpen && (
          <VerseViewModal
           
            globalVersions={globalVersions}
            bibleData={bibleData}
            verseCount={verseCount}
            setVerseViewOpen={setVerseViewOpen}
            projectSlctVerse={projectSlctVerse}
            setProjectSlctVerse={setProjectSlctVerse}
            bibleBoxProjector={bibleBoxProjector}
            setBibleBoxProjector={setBibleBoxProjector}
          />
        )}
      </div>
    </div>
  );
}
