import { useContext, useEffect, useState } from "react";
import { bookNames } from "../../bibleMeta";
import { Stack } from "@mui/material";
import "./VerseViewModal.css";
import { useMediaQuery } from "@material-ui/core";
import { BookContext } from "../../context/BibleProvider";
export default function VerseView({
  
  globalVersions,
  bibleData,
  verseCount,
  setVerseViewOpen,
  user,
  setProjectSlctVerse,
  projectSlctVerse,
  bibleBoxProjector,
  setBibleBoxProjector,
}) {
  const { oldBookNo, oldChapterNo } = useContext(BookContext);
  const [currentVerse, setCurrentVerse] = useState(bibleBoxProjector?projectSlctVerse:1);
  const [versePerSlide, setVersePerSlide] = useState(1);
  useEffect(() => {
    if (bibleBoxProjector) {
      setCurrentVerse(projectSlctVerse);
    }
  });
  const renderVersions = verseNo => {
    const elements = [];
    globalVersions.forEach((version, index) => {
      elements.push(
        <div key={index} className="version">
          <h2 className="version-index">
            {bookNames[version]
              ? bookNames[version][Number(oldBookNo - 1)]
              : bookNames["english_nkjv"][Number(oldBookNo - 1)]}
            {oldChapterNo}: {bibleBoxProjector ? projectSlctVerse : verseNo}
          </h2>
          <div className="version-verse">
            {bibleBoxProjector
              ? bibleData[version][projectSlctVerse]
              : bibleData[version][verseNo]}
          </div>
        </div>
      );
    });
    return elements;
  };

  const renderVerses = () => {
    const elements = [];

    for (
      let i = Number(currentVerse);
      i < Number(currentVerse) + Number(versePerSlide);
      i++
    ) {
      if (i <= verseCount) {
        elements.push(
          <Stack direction="row" spacing={1}>
            {renderVersions(i)}
          </Stack>
        );
      }
    }

    return elements;
  };

  const renderVerseOptions = () => {
    const elements = [];
    for (let i = 1; i <= verseCount; i++) {
      elements.push(
        <option key={i} value={i}>
          Verse {i}
        </option>
      );
    }

    return elements;
  };

  const nextVerse = () => {
    if (currentVerse < Number(verseCount)) {
      const updateCurrentVerse = bibleBoxProjector
        ? projectSlctVerse + 1
        : currentVerse + 1;
      setCurrentVerse(updateCurrentVerse);
    }
    setBibleBoxProjector(false);
  };

  const previousVerse = () => {
    if (currentVerse > 1) {
      const updateCurrentVerse = bibleBoxProjector
        ? projectSlctVerse - 1
        : currentVerse - 1;
      setCurrentVerse(updateCurrentVerse);
    }
    setBibleBoxProjector(false);
  };

  const handleVerseChange = event => {
    const updateCurrentVerse = Number(event.target.value);
    setCurrentVerse(updateCurrentVerse);
  };
  const [safariBrowser, setSafariBrowser] = useState(false);

  const projection = () => {
    const element = document.querySelector(".verse-view-modal");
    if (!element) {
      console.log("Element not found");
      return;
    }

    const isSafari =
      /^((?!chrome|android).)*safari/i.test(navigator.userAgent) &&
      !navigator.userAgent.includes("Chrome");

    if (isSafari) {
      setSafariBrowser(true);
      if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else {
        console.warn("webkitRequestFullscreen not supported");
      }
    } else {
      setSafariBrowser(false);
      element.requestFullscreen?.() ||
        element.msRequestFullscreen?.() ||
        element.mozRequestFullScreen?.();
    }
  };

  useEffect(() => {
    projection();
    const onFullScreenChange = () => {
      const isFullScreenActive =
        document.fullscreenElement || document.webkitFullscreenElement;
      if (!isFullScreenActive) {
        setVerseViewOpen(false);
      }
    };
    document.addEventListener("fullscreenchange", onFullScreenChange);
    document.addEventListener("webkitfullscreenchange", onFullScreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", onFullScreenChange);
      document.removeEventListener(
        "webkitfullscreenchange",
        onFullScreenChange
      );
    };
  }, [projectSlctVerse, bibleBoxProjector]);

  const closeVerseView = () => {
    try {
      if (document.fullscreenElement || document.webkitFullscreenElement) {
        // Safely exit fullscreen based on the available API
        document
          .exitFullscreen?.()
          .catch(error => console.warn("Error exiting fullscreen:", error)); // Catch and log any errors

        document.webkitExitFullscreen?.(); // Safari
        document.msExitFullscreen?.(); // Internet Explorer
        document.mozCancelFullScreen?.(); // Older Firefox
      }
    } catch (error) {
      console.error("Error while handling fullscreen exit:", error);
    } finally {
      setVerseViewOpen(false); // Close the view regardless of fullscreen state
    }
  };

  const ifMobile = useMediaQuery("(max-width:520px)");
  return (
    <>
     
      <div
        className={`modal verse-view-modal ${
          ifMobile && safariBrowser ? "ios-fullscreen" : ""
        }`}
        tabIndex="0"
       
      >
        <div className="verse-view-content" style={{ overflowY: "auto" }}>
          <div className="header">
            <div className="verse-view-controls">
              <button
                onClick={() => {
                  previousVerse();
                }}
                className="prev-button"
              >
                &#60;
              </button>
              <select
                value={bibleBoxProjector ? projectSlctVerse : currentVerse}
                onChange={handleVerseChange}
                className={safariBrowser ? "safariBrowVerOpt" : "verseOptions"}
                style={{ overflowY: "scroll" }}
              >
                {renderVerseOptions()}
              </select>
              <button
                className="next-button"
                onClick={() => {
                  nextVerse();
                }}
              >
                &#62;
              </button>
              <select
                className={
                  safariBrowser ? "safariBrowslctverpersld" : "slctverpersld"
                }
                value={versePerSlide}
                onChange={event => {
                  setVersePerSlide(Number(event.target.value));
                  setBibleBoxProjector(false);
                }}
              >
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
              <span className="perslide-text"> Verse(s) per slide</span>
            </div>
            {safariBrowser ? (
              <span className="safariBrowClsBtn" onClick={closeVerseView}>
                &times;
              </span>
            ) : (
              <span className="close-button" onClick={closeVerseView}>
                &times;
              </span>
            )}
          </div>
          <div className="verse-view-verses">{renderVerses()}</div>
        </div>
      </div>
    </>
  );
}
