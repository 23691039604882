import React, { createContext, useState,useEffect, useRef } from "react";
import { useMediaQuery } from "@mui/material";
const BookContext = createContext();

const BibleProvider = ({ children }) => {
  const [bookNo, setBookNo] = useState(localStorage.getItem("book_no") ?? 1);
  const [oldBookNo, setOldBookNo] = useState(bookNo);
  const [chapterNo, setChapterNo] = useState(
    localStorage.getItem("chapter_no") ?? 1
  );
  const [oldChapterNo, setOldChapterNo] = useState(chapterNo);
  const [verseNo, setVerseNo] = useState(localStorage.getItem("verse_no") ?? 1);
  const [verseCount, setVerseCount] = useState(0);
  const [versesLoading, setVersesLoading] = useState(false);
  const [chapterLoading,
    setChapterLoading] = useState(false);
  const ifMobile = useMediaQuery("(max-width: 490px)");
     const ifTablet = useMediaQuery(
       "(min-width: 768px) and (max-width: 1024px)"
     );
  const ifLargerDevice = useMediaQuery("(min-width:1025px)")
  const setTimeRemoverRef = useRef();
  const addTimeout = timeoutId => {
    if (!setTimeRemoverRef.current) {
      setTimeRemoverRef.current=[]
    } setTimeRemoverRef.current.push(timeoutId);
  };
   const clearAllSetTimes = () => {
    if (!setTimeRemoverRef.current) {
      setTimeRemoverRef.current = [];
    }
      setTimeRemoverRef.current.forEach(clearTimeout);
    setTimeRemoverRef.current = [];
  }
   useEffect(() => {
     localStorage.setItem("book_no", bookNo);
     localStorage.setItem("chapter_no", chapterNo);
     localStorage.setItem("verse_no", verseNo);
   }, [bookNo, chapterNo, verseNo]);
  return (
    <BookContext.Provider
      value={{
        addTimeout,
        clearAllSetTimes,
        bookNo,
        setBookNo,
        oldBookNo,
        setOldBookNo,
        chapterNo,
        setChapterNo,
        oldChapterNo,
        setOldChapterNo,
        verseNo,
        setVerseNo,
        verseCount,
        setVerseCount,
        versesLoading,
        setVersesLoading,
        chapterLoading,
        setChapterLoading,
        ifMobile,
        ifTablet,
        ifLargerDevice,
      }}
    >
      {children}
    </BookContext.Provider>
  );
};

export { BookContext, BibleProvider };
